.pricing-content {
}
.single-pricing {
  background: rgb(25, 128, 68);
  padding: 40px 20px;
  border-radius: 5px;
  position: relative;
  z-index: 2;
  border: 1px solid #eee;
  box-shadow: 0 10px 40px -10px rgba(0, 64, 128, 0.09);
  transition: 0.3s;
}
@media only screen and (max-width: 480px) {
  .single-pricing {
    margin-bottom: 30px;
  }
}
.single-pricing:hover {
  box-shadow: 0px 60px 60px rgba(0, 0, 0, 0.1);
  z-index: 100;
  transform: translate(0, -10px);
}
.price-label {
  color: #fff;
  background: #ffaa17;
  font-size: 20px;
  width: 160px;
  margin-bottom: 15px;
  display: block;
  -webkit-clip-path: polygon(
    100% 0%,
    90% 50%,
    100% 100%,
    0% 100%,
    0 50%,
    0% 0%
  );
  clip-path: polygon(100% 0%, 90% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
  margin-left: -20px;
  /* position: absolute; */
}
.spec {
  font-size: 14px;
}
.price-head h2 {
  font-weight: 600;
  margin-bottom: 0px;
  text-transform: capitalize;
  font-size: 26px;
}
.price-head span {
  display: inline-block;
  background: #ffaa17;
  width: 6px;
  height: 6px;
  border-radius: 30px;
  margin-bottom: 20px;
  margin-top: 15px;
}
.price {
  font-weight: 500;
  font-size: 50px;
  margin-bottom: 0px;
}
.single-pricing {
}
.single-pricing h5 {
  font-size: 14px;
  margin-bottom: 0px;
  text-transform: uppercase;
}
.single-pricing ul {
  list-style: none;
  margin-bottom: 20px;
  margin-top: 30px;
}
.spec2 ul {
  padding: 0;
}

.single-pricing ul li {
  line-height: 35px;
}
.single-pricing a {
  background: none;
  border: 2px solid #ffaa17;
  border-radius: 5000px;
  color: #ffaa17;
  display: inline-block;
  font-size: 16px;
  overflow: hidden;
  padding: 10px 45px;
  text-transform: capitalize;
  transition: all 0.3s ease 0s;
}
.single-pricing a:hover,
.single-pricing a:focus {
  background: #ffaa17;
  color: #fff;
  border: 2px solid #ffaa17;
}
.single-pricing-white {
  background: #19b52c;
}
.single-pricing-white ul li {
  color: #fff;
}
.single-pricing-white h2 {
  color: #fff;
}
.single-pricing-white h1 {
  color: #fff;
}
.single-pricing-white h5 {
  color: #fff;
}
