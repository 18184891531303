.pozadina1 {
  background-image: url(/assets/images/slike/caley-vanular-1jeHdhQr8T8-unsplash.jpg) !important;
}
.pozadina3 {
  background-image: url(/assets/images/slike/valery-sysoev-LDAirERNzew-unsplash.jpg) !important;
}
.webdetalji {
  background-image: url(/assets/images/slike/webdetails.jpg) !important;
}
.bg_image--17 {
  background-image: url(/assets/images/bg/contactus.jpg) !important;
}

/* .pozicija {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
} */
/* .slider-pozicija {
  width: 100%;
  display: inline-block;
} */
